<template lang="pug">

.firebaseui(ref="firebaseUiContainer")
  #firebaseui-auth-container

</template>

<script>

const DEBUG = 0;

import { auth } from '@/firebase';

import { 
  GoogleAuthProvider, 
  FacebookAuthProvider, 
  EmailAuthProvider, 
} from 'firebase/auth';

import firebase from 'firebase/app'
import * as firebaseui from 'firebaseui';
import 'firebaseui/dist/firebaseui.css';

import { useUserStore } from '@/store/user';

export default {

  name: 'FirebaseUi',

  data(){
    return {
      user: useUserStore(),
      authUI: null,
      error: null,
    }
  },

  methods: {

    loadFirebaseUI(){

      const uiConfig = {
        callbacks: {
          signInSuccessWithAuthResult: function( authResult, redirectUrl ){
            if( DEBUG > 2 ) console.log( 'Sign in successful!', { authResult, redirectUrl } );
            return false; // must return false as to not redirect url
          },
        },
        signInFlow: 'popup',
        signInOptions: [
          GoogleAuthProvider.PROVIDER_ID,
          FacebookAuthProvider.PROVIDER_ID,
          EmailAuthProvider.PROVIDER_ID
        ]
      };

      const aui = firebaseui.auth.AuthUI;

      const ui = aui.getInstance() 
        ? aui.getInstance() 
        : new firebaseui.auth.AuthUI( auth );

      this.$emit( 'firebaseUiCreated', ui );

      if( DEBUG ) console.log( 'Starting firebaseui' );
      
      try {
        ui.start( "#firebaseui-auth-container", uiConfig );
      } catch( e ){
        if( DEBUG ) console.log( 'ui.start error:', e );
        this.error = e; // silent fail on purpose
      }

    },

    handleIntersection( entries ){
      //if( this.user.signedIn || this.user.signedOut ) return;
      if( DEBUG > 1 ) console.log( 'Observing if firebaseui is visible (will see "starting firebaseui" if so)' );
      // entries is an array of observed elements
      entries.forEach( entry => {
        // console.log( entry, entry.isIntersecting );
        if( entry.isIntersecting ) this.loadFirebaseUI();
      });
    },


  },

  mounted(){

    const self = this;

    
    auth.onAuthStateChanged( async ( user ) => {
      if( DEBUG > 1 ) console.log( 'Auth state changed.', { user } );
      if( user ){
        await this.user.exists( user );
        if( DEBUG ) console.log( 'Auth state changed, user signed in?', this.user.signedIn );
        setTimeout( () => {
          if( DEBUG > 2 ) console.log( 'firebaseUI closeOverlay' );
          self.$emit( 'closeOverlayPlease' );
        }, 5000 );
      } else this.loadFirebaseUI();
    });

    // below is a hack to ensure firebaseui stays working 

    const options = {
      root: null, // Use the viewport as the root
      rootMargin: '0px',
      threshold: 0.5, // 0 means fully hidden, 1 means fully visible
    };

    const observer = new IntersectionObserver( this.handleIntersection, options );
    observer.observe( this.$refs.firebaseUiContainer );

  }

};

</script>

<style lang="scss">
//@DEVNOTE: if you go scoped you cannot style firebaseui instance

.firebaseui {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  .firebaseui-container, .firebaseui-card-content {
    padding: 0;
    margin: 0 auto;
  }
  .firebaseui-idp-list>.firebaseui-list-item, .firebaseui-tenant-list>.firebaseui-list-item {
    text-align: center;
    list-style: none;
  }
.firebaseui-container form {
  //overflow-y: scroll;
}
.firebaseui-title {
  font-size: 1.618em;
}
.firebaseui-label {
  text-transform: capitalize;
}
.firebaseui-input, .firebaseui-textfield {
  color: $gg-dark;
}
.firebaseui-idp-list, .firebaseui-list-item {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
}

.firebaseui-idp-list, .firebaseui-idp-list>.firebaseui-list-item, .firebaseui-tenant-list>.firebaseui-list-item {
  list-style: none !important;
}

.firebaseui .firebaseui-container .firebaseui-card-content .firebaseui-idp-list {
  list-style: none !important;
}

</style>
