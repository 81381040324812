<template lang="pug">

.registration
  .registration-underlay
  .registration-content
    .registration-message
      .content-styles(v-if='user.signedIn')
        h2 Welcome
        p
          strong {{user.name}}
          |, you are free to use the admin panel to manage
          | MLS listings and paperwork.
        button.button.button-standard(@click="closeOverlay") Continue
      div(v-if='user.signedOut')
        .sign-out-message.content-styles(v-if="user.signedOut")
            h2 Hope to see you again!
            p You have successfully been signed out.
            button.button.button-standard.style-1(@click="signedOut()") Okay
      div(v-if='!user.signedIn')
        .registration-offer
          .content-styles
            h2 Hurray!
            p You're just seconds away from a simpler real estate experience.
            p.text-small Create an account or sign in to view the admin panel.
          FirebaseUi(
            @firebaseUiCreated="storeAuthUi"
            @closeOverlayPlease="closeOverlay"
          )
          .content-styles
            p.note.text-small.cursor-pointer.exit(@click="signedOut") Exit

        

</template>

<script>

import FirebaseUi from './FirebaseUi.vue';

import { useUserStore } from '@/store/user';

export default {

  name: 'Registration',

  components: {
    FirebaseUi
  },

  data(){
    return {
      user: useUserStore(),
      authUi: null,
    }
  },

  methods: {

    closeOverlay(){
      // console.log( 'registration cO' );
      this.$emit( 'closeOverlay' );
    },

    signedOut(){
      this.$emit( 'signedOut' );
      // resetting when firebaseui not visble throws error, not needed
      // if( this.authUi ) this.authUi.reset(); 
    },

    storeAuthUi( ui ){
      this.authUi = ui;
    }

  },

};

</script>

<style lang="scss">

.registration {  
  display: flex;
  height: 100%;  
  &-underlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background: $gg-white;
    opacity: .92;
    filter: blur(2px);
    z-index: 1;
    
    justify-content: center; 
  }
  &-content {
    position: relative;
    z-index: 2;
    padding: 2em;
    margin: 2em;
    align-self: center;
    flex-grow: 1;
  }
  &-message {
    margin-bottom: 1em;
  }
  &-offer {
    text-align: center;
  }
  //&-buttons {
    //padding: 2em;
    .button {
      position: relative;
      display: block;
      width: 100%;
      background-color: transparent;
      padding: 1.618rem;
      font-size: 1.618em;
      margin-bottom: .618em;
      border: none;
      border-width: 2px;
      border-style: solid;
      border-color: $gg-blue;
      font-family: inherit;
      cursor: pointer;
      user-select: none;
      color: $gg-blue;
      transition: color .5s ease-out;
      border-radius: .2rem;
      z-index: 1;
    }

  .exit {
    cursor: pointer;
    color: $gg-dark !important; // has to be dark to be seen on mobile
    opacity: 1 !important;
    border-left: none !important;
  }

}

</style>
